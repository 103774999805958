import React, { useState, useEffect } from "react";
import DatePicker from "react-datepicker";
import ru from 'date-fns/locale/ru';
import CloseSidebar from "../../Main/CloseSidebar";
import toast, { Toaster } from 'react-hot-toast';
import axios from "axios";

import "react-datepicker/dist/react-datepicker.css"

function Graphics(props) {
    CloseSidebar();

    const API_SCRIPT = process.env.REACT_APP_API_SCRIPT;

    // прелоадер при получении времени
    const [isLoaded, setIsLoaded] = useState(false);
    const [items, setItems] = useState([]);
    const [clients, setClients] = useState([]);
    const [selectDate, setDate] = useState(new Date());
    const [isModal, setModal] = useState(false);
    const [isModalContent, setModalContent] = useState([]);
    const [includeDates, setIncludeDate] = useState([]);

    // закрывашка и очистка контента модали
    const onClose = () => {
        setModal(false);
        setModalContent([]);
    }

    const config = {
        headers : {
            'Content-Type' : 'application/x-www-form-urlencoded'
        }
    };

    // кнопка для пикера
    const DatePickerButton = React.forwardRef(({value, onClick}, ref) => (
        <svg width="24" height="26" viewBox="0 0 24 26" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={onClick} ref={ref}>
            <path d="M1.60742 9.97154H22.402" stroke="#A3B8CE" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M17.1815 14.5279H17.1923" stroke="#A3B8CE" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M12.0057 14.5279H12.0165" stroke="#A3B8CE" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M6.8182 14.5279H6.82901" stroke="#A3B8CE" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M17.1815 19.0621H17.1923" stroke="#A3B8CE" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M12.0057 19.0621H12.0165" stroke="#A3B8CE" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M6.8182 19.0621H6.82901" stroke="#A3B8CE" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M16.7181 1.33325V5.17249" stroke="#A3B8CE" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M7.29235 1.33325V5.17249" stroke="#A3B8CE" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M16.9446 3.17566H7.06612C3.63999 3.17566 1.5 5.08425 1.5 8.59252V19.1504C1.5 22.7139 3.63999 24.6666 7.06612 24.6666H16.9338C20.3708 24.6666 22.5 22.747 22.5 19.2387V8.59252C22.5108 5.08425 20.3816 3.17566 16.9446 3.17566Z" stroke="#A3B8CE" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
    ));

    // месяцы, дни, дни сокращенно
    let months = ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'];
    let days = ['воскресенье', 'понедельник', 'вторник', 'среда', 'четверг', 'пятница', 'суббота'];

    // формирование даты для титла
    const selectDateTitle = selectDate.getDate() + ' ' + months[selectDate.getMonth()] + ' (' + days[selectDate.getDay()] + ')';

    // получение цифорок даты
    const selectDateArr = [
        selectDate.getDate(),
        selectDate.getMonth() + 1,
        selectDate.getFullYear()
    ];

    // получение доступных для записи дат
    const loadIncludeDates = () => {
        const data = {
            path: 'instructor/practice/dates',
            school_id: props.auth.school_id,
            instructor_id: props.auth.instructor_id,
        };

        axios.post(API_SCRIPT, data, config).then(response => {
            response = response.data;

            if (response.status === 'done') {
                setIncludeDate([]);
                if (response.dates !== false) {
                    let includeDatesNew = response.dates;
                    includeDatesNew.forEach((item, index) => {
                        includeDatesNew[index] = new Date(item);
                    });

                    setIncludeDate(includeDatesNew);
                }
            }
        })
    }

    // обновлялка времени
    useEffect(() => {
        setIsLoaded(true);
        setItems([]);

        loadIncludeDates();

        const times = {
            path: 'instructor/practice/times',
            school_id: props.auth.school_id,
            instructor_id: props.auth.instructor_id,
            date: selectDateArr.join('.')
        };

        axios.post(API_SCRIPT, times, config).then(response => {
            response = response.data;
            setIsLoaded(false);
            if (response.status === 'done') {
                setItems(response.times);
            }
        })
    }, [selectDate]);

    // отмена занятия
    const statusPractice = (status, client = false) => {
        const data = {
            path: 'instructor/practice/status',
            client_id: isModalContent.client_id ?? (client.id ?? 0),
            school_id: props.auth.school_id,
            instructor_id: props.auth.instructor_id,
            date: selectDateArr.join('.'),
            time_id: isModalContent.time_id,
            time_title: isModalContent.time_title,
            status: status
        };

        axios.post(API_SCRIPT, data, config).then(response => {
            response = response.data;
            if (response.status === 'done') {
                let timesItems = items;

                if (status === 1) {
                    timesItems[isModalContent.item].status = 'Назначено';
                    timesItems[isModalContent.item].client_id = client.id;
                    timesItems[isModalContent.item].name = client.name;
                    timesItems[isModalContent.item].patronymic = client.patronymic;
                    timesItems[isModalContent.item].phone = client.phone;
                    timesItems[isModalContent.item].phoneStr = client.phoneFormat;
                    timesItems[isModalContent.item].secondName = client.lastname;
                } else if (status === 0 || status > 2) {
                    switch (status) {
                        case 3: timesItems[isModalContent.item].status = 'ДТП'; break;
                        case 4: timesItems[isModalContent.item].status = 'Выходной'; break;
                        case 5: timesItems[isModalContent.item].status = 'Ремонт'; break;
                        case 6: timesItems[isModalContent.item].status = 'Больничный'; break;
                        case 7: timesItems[isModalContent.item].status = 'Отпуск'; break;
                        case 8: timesItems[isModalContent.item].status = 'Свободно'; break;
                        case 0: timesItems[isModalContent.item].status = 'Свободно'; break;
                        case 9: timesItems[isModalContent.item].status = 'Экзамен'; break;
                        default: break;
                    }

                    delete timesItems[isModalContent.item].client_id;
                    delete timesItems[isModalContent.item].name;
                    delete timesItems[isModalContent.item].patronymic;
                    delete timesItems[isModalContent.item].phone;
                    delete timesItems[isModalContent.item].phoneStr;
                    delete timesItems[isModalContent.item].recordCount;
                    delete timesItems[isModalContent.item].secondName;
                } else {
                    timesItems[isModalContent.item].status = 'Неявка';
                }

                toast.success('Успешно', {
                    duration: 5000,
                    position: 'bottom-center'
                });

                setItems(timesItems);
            } else {
                toast.error(response.error, {
                    duration: 5000,
                    position: 'bottom-center'
                });
            }

            setModal(false);
        })
    }

    // модальное окно
    const Modal = ({visible = false, content}) => {
        if (!visible) return null;

        let visibleForLearn = false;
        let visibleForSkip = true;
        let visibleForFree = false;

        switch (content.status) {
            case 'Назначено': visibleForLearn = true; break;
            case 'Неявка': visibleForLearn = true; visibleForSkip = false; break;
            case 'Пробное': visibleForLearn = true; break;
            case 'ДТП': visibleForFree = true; break;
            case 'Выходной': visibleForFree = true; break;
            case 'Ремонт': visibleForFree = true; break;
            case 'Больничный': visibleForFree = true; break;
            case 'Отпуск': visibleForFree = true; break;
            case 'Экзамен': visibleForFree = true; break;
            case 'Дополнительное занятие': visibleForLearn = true; break;
            default: break;
        }

        const clientsElements = clients.map((client, index) => {
            return (
                <div className="clients-item mb-2 mt-2 pl-3 pr-3" key={index} onClick={() => statusPractice(1, client)} data-client={ client.lastname + ' ' + client.name + ' ' + client.patronymic }>
                    <div className="d-flex justify-content-between">
                        <div className="text-bold">{ client.lastname }</div>
                    </div>
                    <div className="d-flex justify-content-between">
                        <div>{ client.name } { client.patronymic }</div>
                    </div>
                </div>
            )
        });

        return (
            <div className="practice-graphics-modal position-fixed">
                <div className="practice-graphics-modal-dialog text-center">
                    <div className="practice-graphics-modal-header">
                        <div className="practice-graphics-modal-modal-title mb-0">{ selectDateTitle }</div>
                        <div className="practice-graphics-modal-modal-title">{ content.time_title }</div>
                        <div className="practice-graphics-modal-modal-title">{ content.secondName } { content.name } { content.patronymic }</div>
                        <div className="practice-graphics-modal-modal-title">
                            <a href={'tel:' + content.phone}>{ content.phoneStr }</a>
                        </div>
                    </div>
                    <div className="practice-graphics-modal-status-change">
                        { visibleForLearn || visibleForFree ? (
                            <React.Fragment>
                                <div className={'practice-graphics-modal-confirm-btn-block ' + (visibleForLearn ? 'd-flex' : 'd-none')}>
                                    <div className={'practice-graphics-modal-confirm-btn ' + (!visibleForSkip ? 'd-none' : '')}>
                                        <div className="confirm-btn-cancel" onClick={() => statusPractice(2)}>Неявка</div>
                                    </div>
                                    <div className={'practice-graphics-modal-confirm-btn ' + (!visibleForSkip ? 'm-auto' : '')}>
                                        <div className="confirm-btn-yes" onClick={() => statusPractice(8)}>Отменить</div>
                                    </div>
                                </div>
                                <div className={'practice-graphics-modal-confirm-btn-block ' + (visibleForFree ? 'd-flex' : 'd-none')}>
                                    <div className="practice-graphics-modal-confirm-btn m-auto">
                                        <div className="confirm-btn-yes" onClick={() => statusPractice(0)}>Свободно</div>
                                    </div>
                                </div>
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <div className="practice-graphics-modal-status-change-item mb-3">
                                    <div className="item-title m-auto" style={{width: '300px'}}>Записать ученика</div>
                                    <div className="items clients-items m-auto" style={{width: '300px', display: 'none'}}>
                                        <div className="clients-search position-relative p-3">
                                            <input type="text" className="form-control live-search-clients" placeholder="ФИО" style={{paddingLeft: '35px'}} />
                                            <div className="position-absolute" style={{top: '25px', left: '25px'}}>
                                                <i className="fas fa-search"></i>
                                            </div>
                                        </div>
                                        <div className="clients custom-scroll" style={{maxHeight: '290px', overflowY: 'auto', paddingBottom: '10px'}}>
                                            { clientsElements }
                                        </div>
                                    </div>
                                </div>
                                <div className="practice-graphics-modal-status-change-item">
                                    <div className="item-title m-auto" style={{width: '300px'}}>Сменить статус</div>
                                    <div className="items m-auto" style={{width: '300px', display: 'none'}}>
                                        <div onClick={() => statusPractice(3)}>ДТП</div>
                                        <div onClick={() => statusPractice(4)}>Выходной</div>
                                        <div onClick={() => statusPractice(5)}>Ремонт</div>
                                        <div onClick={() => statusPractice(6)}>Болезнь</div>
                                        <div onClick={() => statusPractice(7)}>Отпуск</div>
                                        <div onClick={() => statusPractice(9)}>Экзамен</div>
                                    </div>
                                </div>
                            </React.Fragment>
                        ) }
                    </div>
                    <div className="text-bold text-gray mt-3" onClick={onClose}>Закрыть</div>
                </div>
            </div>
        )
    }

    // сохранение выбранного контента и запуск модали
    const timeClick = (item) => {
        setClients([]);

        const data = {
            path: 'instructor/clients',
            school_id: props.auth.school_id,
            instructor_id: props.auth.instructor_id
        };

        axios.post(API_SCRIPT, data, config).then(response => {
            response = response.data;
            setIsLoaded(false);
            if (response.status === 'done') {
                setClients(response.clients);
            }
        })

        setModalContent(item);
        setModal(true);
    }

    // формирование объекта из элементов времени
    const itemsElements = items.map((item, index) => {
        let color = 'bg-white',
            secondName = item.secondName != null ? item.secondName : 'Дополнительное занятие',
            name = item.name != null ? item.name[0] + '.' : '',
            patronymic = item.patronymic != null ? item.patronymic[0] + '.' : '';

        if (item.status !== 'Свободно') {
            switch (item.status) {
                case 'Назначено':
                    color = 'bg-green';
                    if (item.type === 'service') color = 'bg-warning';
                    break;
                case 'Неявка': color = 'bg-red'; break;
                case 'Пробное': color = 'bg-primary'; break;
                case 'Дополнительное занятие': color = 'bg-warning'; break;
                default: color = 'bg-gray'; break;
            }
        }

        item.item = index;

        return (
            <div className={'practice-graphics-item d-flex align-items-center ' + color} key={index} onClick={() => timeClick(item)}>
                <div className="practice-graphics-item-time">{item.time_title}</div>
                <div className="practice-graphics-item-title">{(item.status === 'Назначено' || item.status === 'Неявка' || item.status === 'Пробное' || item.status === 'Дополнительное занятие') ? (secondName + ' ' + name + patronymic + (item.status === 'Пробное' ? ' (пробное)' : '')) : item.status}{(item.recordCount && item.status !== 'Пробное') ? ' (' + item.recordCount + ')' : ''}</div>
            </div>
        )
    });

    return (
        <React.Fragment>
            <div className="content">
                <div className="practice-graphics-page position-relative">
                    <div className="practice-graphics-block">
                        <div className="practice-graphics-header">
                            <DatePicker
                                selected={selectDate}
                                onChange={date => setDate(date)}
                                customInput={<DatePickerButton />}
                                locale={ru}
                                includeDates={includeDates}
                            />
                            <div className="practice-graphics-day-title">{selectDateTitle}</div>
                        </div>
                        <div className="practice-graphics-body">
                            { isLoaded === false ? (
                                <div className="practice-graphics-times custom-scroll">
                                    {itemsElements.length !== 0 ? (
                                        itemsElements
                                    ) : (
                                        <div className="text-center text-bold mt-3">Нет расписания на выбранную дату!</div>
                                    )}
                                </div>
                            ) : (
                                <div className="loader">
                                    <div className="circle"></div>
                                    <div className="circle"></div>
                                    <div className="circle"></div>
                                    <div className="circle"></div>
                                    <div className="circle"></div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                visible={isModal}
                content={isModalContent}
            />
            <Toaster />
        </React.Fragment>
    )
}

export default Graphics;
